<template>
  <div>
    <v-progress-linear
      v-if="loadingReminderList"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div class="pa-0 ma-0 mt-3" v-if="listOfReminders.length">
      <v-row :no-gutters="$vuetify.breakpoint.xsOnly" :class="!$vuetify.breakpoint.xsOnly ? 'pa-1 pt-2': ''">
        <v-col sm="6" md="6" xs="12" cols="12" v-for="(item, index) in listOfReminders" :key="index" class="pt-1 pb-0">
          <v-card class="mb-1 ml-1 mr-1" :color="$vuetify.theme.dark ? '#1e1e1e' : '#fff'">
            <v-card-text>
              <v-row align="center" no-gutters>
                <v-col cols="5" class="font-weight-bold">{{ item.subject }}</v-col>
                <v-col cols="5">{{ item.remindat ? $formatter.formatDate(item.remindat, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : '' }}</v-col>
                <v-col cols="2">
                  <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" dark @click="getSingleRecordHandler(item._id)">
                    {{ commonButtonLoader ? 'mdi-spin mdi-loading' : 'mdi-pencil-circle-outline' }}
                  </v-icon>
                  <v-icon color="red" dark @click="deleteHandler(item._id)" class="pl-1">
                    {{ commonButtonLoader ? 'mdi-spin mdi-loading' : 'mdi-delete-circle-outline' }}
                  </v-icon>
                </v-col>
                <v-col cols="12" class="font-weight-bold"><span v-html="item.body"></span></v-col>
                <v-col cols="12">{{ item.notifyToText }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-alert prominent text type="info" class="mt-2">
        <v-row>
          <v-col class="grow">{{ $t('noReminders') }}</v-col>
          <!-- <v-btn fab x-small @click="addReminderHandler()" class="mr-3" color="success">
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
        </v-row>
      </v-alert>
    </div>
    <v-dialog v-model="dialog" width="700" persistent>
      <form-template :references.sync="formReferences" :model="modelObj" ref="fieldsReference" @closeDialog="closeDialogHandler" v-if="dialog">
        <template slot="extraDetails" v-if="modelObj.send_auto_reply">
          <v-container>
            <v-layout wrap class="ma-0 pa-0">
            <v-flex lg12 xs12 :class="$vuetify.theme.dark ? 'dark_theme_editor' : ''">
              <p>{{$t('autoReply')}}</p>
              <ckeditor :editor="editor" v-model="modelObj.auto_reply" :config="editorConfig" ref="editor"></ckeditor>
            </v-flex>
            </v-layout>
          </v-container>
        </template>
      </form-template>
    </v-dialog>
    <v-speed-dial bottom right fixed class="mb-12">
      <template v-slot:activator>
        <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
          fab dark @click="addReminderHandler()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import Editor from '@/ckeditor'
import FormTemplate from '@/components/FormTemplate'
export default {
  props: ['isView', 'ticketObj'],
  data () {
    return {
      commonButtonLoader: false,
      MODULE: 'ticketreminders',
      dialog: false,
      listOfReminders: [],
      editor: Editor,
      toggle_exclusive: 2,
      showCommentBox: false,
      userId: this.$store.state.auth.userDetails._id,
      modelObj: {
        ticket_id: this.$route.params.ticket_id,
        comment: ''
      },
      configuration: {
        height: '250px',
        sharedSpaces: { 
          top: 'toolbarLocation' 
        },
        toolbar: {
          items: [ 'bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        },
        mention: {},
      },
      listOfUsers: [],
      reRender: 0,
      loadingReminderList: false
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getsignalRConnection', 'getListOfGroups', 'systemDetails', 'formType']),
    formReferences () {
      return {
        title: this.modelObj._id ? this.$t('updateTicketReminder') : this.$t('addTicketReminder'),
        fromsettings: true,
        properties: [
        {
          model: 'remindat',
          type: this.formType.DATE_TIME_PICKER,
          rules: this.$_requiredValidation,
          picker: 'datePicker',
          menu: 'dateTimeMenu',
          timePicker: 'timePicker',
          label: this.$t('date'),
          class: 'sm6 xs12',
          color: 'primary lighten-1'
        },
        {
          model: 'notifyto',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getUsers,
          term: 'getUsers',
          select_text: 'name',
          select_value: '_id',
          label: this.$t('user'),
          multiple: true,
          class: 'xs12 sm6'
        }, {
          model: 'subject',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('subject'),
          class: 'xs12'
        }, {
          model: 'body',
          type: this.formType.HTMLFIELD,
          rules: [],
          label: this.$t('body'),
          class: 'xs12'
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.modelObj._id ? this.$t('update') : this.$t('save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          is_show: true
        }, {
          name: 'close',
          color: 'error',
          click: this.closeDialogHandler,
          label: this.$t('close'),
          is_show: true
        }]
      }
    }
  },
  components: {
    FormTemplate
  },
  mounted () {
    this.getAllReminders()
    let text = 'deleteMsg'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        let payload = { ids: data.ids, url: `${this.MODULE}/delete_multiple`, module: this.MODULE }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'ticketreminders') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getAllReminders()
      }
    })
  },
  methods: {
    getAllReminders () {
      this.loadingReminderList = true
      this.$api.execute('get', `/ticketreminders/get_by_ticket/${this.ticketObj._id}`)
        .then(({ data }) => {
          data.forEach(item => {
            item.notifyToText = ''
            if (item.notifyto && item.notifyto.length) {
              item.notifyToText = this.getUsers.filter(x => item.notifyto.includes(x._id)).map(x => [x.email]).join(' ')
            }
          })
          this.listOfReminders = data
          this.loadingReminderList = false
        })
    },
    getSingleRecordHandler (id) {
      this.$api.getSingleRecordHandler(`${this.MODULE}/${id}`).then(({ data }) => {
        if (data) {
          // data.datePicker = this.$formatter.formatDate(data.remindat, 'DD.MM.YYYYTHH.mm.ss', 'YYYY-MM-DDTHH:mm:ss')
          data.dateTimePicker = this.$formatter.formatDate(data.remindat, `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DD')
          data.timePicker = this.$formatter.formatDate(data.remindat, `${this.userDetails.dateformat} HH:mm`, 'HH:mm')
          data.remindat = this.$formatter.formatDate(data.remindat, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat} HH:mm`)
          this.modelObj = data
          this.dialog = true
        }
      })
    },
    saveUpdateHandler () {
      if (this.$refs.fieldsReference.$refs.validateForm.validate()) {
        this.loading = true
        let model = this.modelObj
        model.ticket_id = this.ticketObj._id
        model.remindat = this.$formatter.formatDate(model.remindat, `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DDTHH:mm:ss')
        let url = this.MODULE
        this.$api.saveUpdateHandler(url, model)
          .then((response) => {
            this.$root.$emit('snackbar', response.snackbar)
            this.closeDialogHandler()
            this.getAllReminders()
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
      this.$store.dispatch('getTicketCategory')
    },
    closeDialogHandler () {
      this.modelObj = {}
      this.dialog = false
    },
    addReminderHandler () {
      const ticketNumber = this.ticketObj.number ? `#${this.ticketObj.number}` : ''
      this.modelObj = {
        notifyto: [this.userDetails._id],
        subject: `Reminder #${this.listOfReminders.length + 1}`,
        body: `Reminder to check Ticket ${ticketNumber}`
      }
      this.dialog = true
    },
    deleteHandler (id) {
      let data = { module: this.MODULE, ids: [id] }
      this.$eventBus.$emit('deleteItems', data)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
  }
}
</script>
<style>
.custom-item  {
  display: block;
}
</style>
